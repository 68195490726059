// TODO document!
document.addEventListener('DOMContentLoaded', function() {

    var Footerdion = {};
    window.Footerdion = Footerdion;

    Footerdion.AUTHOR = 'Choan Gálvez';
    Footerdion.VERSION = '0.0.1';

    var widgetAccordion = '[data-footerdion]';
    var widgetHeading = '[data-footerdion-heading]';
    var widgetPanel = '[data-footerdion-panel]';

    var widgetClass = 'footerdion';
    var widgetClassTrigger = widgetClass + '__trigger';
    var widgetClassHeading = widgetClass + '__heading';
    var widgetClassPanel = widgetClass + '__panel';

    var idCounter = 0;

    Footerdion.create = function() {

        var self;
        var panels;
        var headings;
        var triggers;

        var widgets = document.querySelectorAll(widgetAccordion);

        for (let i = 0; i < widgets.length; ++i) {
            self = widgets[i];

            // Exit early if already initialized (prevent double initialization)
            if (self.footerdion) {
                continue;
            }

            // Exit early if display width is over maximum.
            if (self.hasAttribute('data-footerdion-max-width')) {
                var maxWidth = self.getAttribute('data-footerdion-max-width');
                const breakpoint = window.matchMedia( '(max-width: ' + maxWidth + ')' );
                if (!breakpoint.matches) {
                    continue;
                }
            }

            if (!self.hasAttribute('id')) {
                self.id = widgetClass + '-' + i;
            }

            self.classList.add(widgetClass);

            Footerdion.setupPanels(self.id, document.querySelectorAll('#' + self.id + ' ' + widgetPanel));
            Footerdion.setupHeadingButtons(self.id, document.querySelectorAll('#' + self.id + ' ' + widgetHeading));

            triggers = document.querySelectorAll('#' + self.id + ' .' + widgetClassTrigger);

            for (let t = 0; t < triggers.length; ++t) {
                triggers[t].addEventListener('click', Footerdion.handleClick);
                // triggers[t].addEventListener('keydown', Footerdion.handleKeydown.bind(self));
            }

        }

    };

    Footerdion.setupPanels = function(id, panels) {
        var panel;

        for (let i = 0; i < panels.length; ++i) {
            panel = panels[i];
            if (!panel.hasAttribute('id')) {
                panel.id = id + '__panel-' + i;
            }

            ariaHidden(panel, true);
            panel.classList.add(widgetClassPanel);
        }
    };

    Footerdion.setupHeadingButtons = function(id, headings) {
        var heading;
        var trigger;
        var targetState;
        var button;

        for (let i = 0; i < headings.length; ++i) {
            heading = headings[i];
            button = heading.querySelector('button');
            if (!button) {
                button = document.createElement('button');
                button.setAttribute('type', 'button');
                button.appendChild(document.createTextNode(heading.textContent));
                heading.innerHTML = '';
                heading.appendChild(button);
            }

            targetId = button.getAttribute('aria-controls');
            if (!targetId) {
                targetId = heading.nextElementSibling.id;
                button.setAttribute('aria-controls', targetId);
            }

            button.id = id + '__trigger-' + i;

            heading.classList.add(widgetClassHeading);
            button.classList.add(widgetClassTrigger);

            targetState = document.getElementById(targetId).getAttribute('aria-hidden');

            if ('false' === targetState) {
                ariaExpanded(button, true);
            } else {
                ariaExpanded(button, false);
            }
        }
    };

    Footerdion.handleClick = function(e) {
        var accordionId = this.id.replace(/__trigger.*$/, '');
        var target = document.getElementById(this.getAttribute('aria-controls'));
        var triggers = document.querySelectorAll('#' + accordionId + ' .' + widgetClassTrigger);
        e.preventDefault();
        Footerdion.togglePanel(e, accordionId, target, triggers);
    };

    Footerdion.togglePanel = function(e, accordionId, target, triggers) {
        var trigger = e.target;
        if ('true' === trigger.getAttribute('aria-expanded')) {
            ariaExpanded(trigger, 'false');
            ariaHidden(target, 'true');
        } else {
            ariaExpanded(trigger, 'true');
            ariaHidden(target, 'false');
        }
    };

    Footerdion.init = function() {
        Footerdion.create();
    };

    function ariaHidden(el, state) { el.setAttribute('aria-hidden', state) }
    function ariaExpanded(el, state) { el.setAttribute('aria-expanded', state) }
    function ariaDisabled(el, state) { el.setAttribute('aria-disabled', state) }

    Footerdion.init();
});
